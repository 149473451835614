import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Client/Login";
import Register from "./Client/Register";
import Landingpage from "./Client/Landingpage";
import Home from "./Client/Home";
import About from "./Client/About";
import BMI from "./Client/Modals/BMI";
import LoginForm from "./Client/Calorie";
import DietPlan from "./Client/DietPlan";
import TrackCalories from "./Client/TrackCalories";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Landingpage />} />
          <Route path="/about" element={<About />} />
          <Route path="/home" element={<Home />} />
          <Route path="/modal" element={<BMI />} />
          <Route path="/calorie" element={<LoginForm />} />
          <Route path="/diet" element={<DietPlan />} />
          <Route path="/track" element={<TrackCalories />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
