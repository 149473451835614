import React from 'react'
import "./Program.css";
import { Typography ,Button,CardContent,CardMedia,Card,CardActions} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Program = () => {
    const navigate =useNavigate();
    const tohome=()=>{
        navigate("/home");
    }
    const tocalorie=()=>{
        navigate("/calorie");
    }
    const tobmi=()=>{
        navigate("/modal");
    }
    
  return (
    <div className='program'>
        <div className='program-header'>
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text' >to shape you</span>
        </div>
        <div className='cards'>
            <span>
            <Card sx={{ maxWidth: 345, backgroundColor: '#9c9c9c' }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image="https://images.unsplash.com/photo-1558611848-73f7eb4001a1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80"
                    
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Search Exercise
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Get yourself fit with various exercises and do them properly with instruction
                    </Typography>
                </CardContent>
                <CardActions>
                <Button onClick={tohome} variant="outlined">Explore</Button>
                </CardActions>
            </Card>

            </span>
            <span>
            <Card sx={{ maxWidth: 345, backgroundColor: '#9c9c9c' }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image="https://images.unsplash.com/photo-1629121958394-3be95d8c057c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2Fsb3JpZXN8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Know your Food
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        find your food nutrional break up and track your calories and much more
                    </Typography>
                </CardContent>
                <CardActions>
                <Button onClick={tocalorie} variant="outlined">Explore</Button>
                </CardActions>
            </Card>
            </span>
            <span>
            <Card sx={{ maxWidth: 345, backgroundColor: '#9c9c9c' }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image="https://images.unsplash.com/photo-1618939291225-8d558ea4369f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fGNhbG9yaWVzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Find Your BMI
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Get your BMI by your weight and height and compare it with table given there
                    </Typography>
                </CardContent>
                <CardActions>
                <Button onClick={tobmi} variant="outlined">Explore</Button>
                </CardActions>
            </Card>
            </span>
            <span>
            <Card sx={{ maxWidth: 345, backgroundColor: '#9c9c9c' }}>
                <CardMedia
                    sx={{ height: 140 }}
                    image="https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGRpZXQlMjBwbGFufGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60"
                    title="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Diet Plan
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Find Diet Plan thats suits Your daily life and work Goals and body goals
                    </Typography>
                </CardContent>
                <CardActions>
                <Button variant="outlined">Explore</Button>
                </CardActions>
            </Card>
            </span>
        </div>
    </div>
  )
}

export default Program