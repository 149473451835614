const Fibre = [
  {
    englishName: "Lentils",
    hindiName: "Dal",
    Content: "7.9g per 100g",
  },
  {
    englishName: "Chickpeas",
    hindiName: "Chana",
    Content: "7.6g per 100g",
  },
  {
    englishName: "Black Beans",
    hindiName: "kaale chane",
    Content: "8.7g per 100g",
  },
  {
    englishName: "Quinoa",
    hindiName: "Anaj",
    Content: "2.8g per 100g",
  },
  {
    englishName: "Oats",
    hindiName: "Oats",
    Content: "10.1g per 100g",
  },
  {
    englishName: "Whole Wheat Bread",
    hindiName: "Ghehu ki bread",
    Content: "6.9g per 100g",
  },
  {
    englishName: "Brown Rice",
    hindiName: "Brown rice",
    Content: "2.8g per 100g",
  },
  {
    englishName: "Sweet Potato",
    hindiName: "ShakarKandi",
    Content: "3g per 100g",
  },
  {
    englishName: "Broccoli",
    hindiName: "Gobi",
    Content: "2.6g per 100g",
  },
  {
    englishName: "Spinach",
    hindiName: "Palak",
    Content: "2.2g per 100g",
  },
  {
    englishName: "Brussels Sprouts",
    hindiName: "Sprouts",
    Content: "3.8g per 100g",
  },
];
export default Fibre;