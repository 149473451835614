import React from 'react'
import "./Header.css";
import Logo from "../images/logo.png"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const toabout=()=>{
        navigate("/about");
    }
    const popup=()=>{
        window.alert("Please Login first")
    }
  return (
    <div className="header">
        <img src={Logo} alt='' className='logo'/>
        <ul className='header-menu' >
            <li><Button onClick={popup} variant="text"><span style={{color:"white"}}>Home</span></Button></li>
            <li><Button onClick={toabout} variant="text"><span style={{color:"white"}}>About us</span></Button></li>
            <li><Button  variant="text"><span style={{color:"white"}}>Contact</span></Button></li>
            <li><Button  variant="text"><span style={{color:"white"}}>Login</span></Button></li>
            <li><Button  variant="text"><span style={{color:"white"}}>Demo</span></Button></li>
        </ul>
    </div>
  )
}

export default Header