import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

//import Landingpage from './Landingpage';
const Login = () => {

    const navigate = useNavigate();
    const[loggedin,setLoggedin] = useState(false);
    const[data,setData] = useState({
        email:"",
        password:"",
    })
    const toregister=()=>{
        navigate("/register");
    }
    const handle=(e)=>{
        setData({...data,[e.target.name] : e.target.value})
    }
    const handlebtn= async (e)=>{
        console.log("enterd btn")
        e.preventDefault();
        const {email,password} = data;
        console.log(email);
        const res = await fetch("/login",{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
              },
              body: JSON.stringify({
                 email , password
              })
        })
        

    if(res.ok)
    {
      const datas = await res.json();
      if(datas)
      {
        console.log("logged");
       window.alert("Succesfully Logged in Redirecting to home....")
       setLoggedin(true);
       navigate("/home");
      }
      
    }
    else{
      window.alert("invalid");
      console.log("invalid");
      
    }
    }
  return (
    
    <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    background: `url('https://images.unsplash.com/photo-1581009146145-b5ef050c2e1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGd5bXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60') center/cover no-repeat`,
  }}
>
  <div
    style={{
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '25px',
      borderRadius: '8px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    }}
  >
    <h2 style={{ marginBottom: '20px', marginLeft: '-5px', textAlign: 'center' }}>Login</h2>
    <input
      type="email"
      name="email"
      placeholder="Email"
      value={data.email}
      onChange={handle}
      style={{
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
    <input
      type="password"
      placeholder="Password"
      name="password"
      value={data.password}
      onChange={handle}
      style={{
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        border: '1px solid #ccc',
        borderRadius: '4px',
      }}
    />
    <button
      onClick={handlebtn}
      type="submit"
      style={{
        background: '#007bff',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      Login
    </button>
    <button onClick={toregister} style={{ marginLeft: '15px', marginTop: '10px', color: '#007bff', cursor: 'pointer', border: 'none', background: 'none' }}>
      New User?
    </button>
  </div>
</div>
  )
}

export default Login