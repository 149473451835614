import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate}  from "react-router-dom";

const Register = () => {
  const navigate=useNavigate();
    const [Data, setData] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
  });

  const tohome=()=>{
    navigate("/login")
  }

  const handleInputChange = (e) => {    
    setData({
      ...Data,
      [e.target.name]: e.target.value,
    });
  };


  const submit=async(e)=>{
    e.preventDefault();
    const {fname , lname , email , password} = Data;
    if (!fname || !lname || !email || !password) {
      window.alert("please fill all Fields");
    }
   
    //console.log("regis");
    const res=await fetch("/register",{
      method:"POST",
      headers:{
        "Content-Type" : "application/json"
      },
      body: JSON.stringify({
        fname , lname , email , password
      })
    });

    

    if(res.ok)
    {
      const data = await res.json();
      console.log("Response data:", data.email);
      window.alert("Registered");
      navigate("/login");
    }
    else{
      console.log("Request failed with status:", res.status);
      window.alert("email already exist");
    }

  }


  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   // Perform registration logic here
  //   console.log('Form data submitted:', Data);
  // };

  return (
    <div
  style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: `url('https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=775&q=80" alt="') center/cover no-repeat`,
    backgroundAttachment: 'fixed',
  }}
>
  <form
    method="POST"
    style={{
      width: '400px',
      padding: '20px',
      boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.95)',
      borderRadius: '8px',
    }}
  >
    <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>Register</h2>
    <input
      type="text"
      name="fname"
      placeholder="First Name"
      value={Data.firstName}
      onChange={handleInputChange}
      style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
    />
    <input
      type="text"
      name="lname"
      placeholder="Last Name"
      value={Data.lastName}
      onChange={handleInputChange}
      style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
    />
    <input
    type='email'
    
      name="email"
      placeholder="Email"
      value={Data.email}
      onChange={handleInputChange}
      style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
    />
    <input
      type="password"
      name="password"
      placeholder="Password"
      value={Data.password}
      onChange={handleInputChange}
      style={{ marginBottom: '10px', padding: '10px', width: '100%' }}
    />
    <input
      type="submit"
      value="Register"
      name="login"
      onClick={submit}
      style={{
        background: '#007bff',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        width: '100%',
      }}
    />
    <h4>Already Registered?</h4>
    <Button onClick={tohome} variant="outlined"> Go To Login</Button>
  </form>
  
</div>

  );
};

export default Register;
