import React, { useState } from 'react';
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const LoginForm = () => {
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('male');
  const [activityLevel, setActivityLevel] = useState('');
  const [maintenanceCalories, setMaintenanceCalories] = useState(null);

  const calculateMaintenanceCalories = () => {
    const bmr = calculateBMR();
    const activityFactor = getActivityFactor(activityLevel);
    const calculatedMaintenanceCalories = Math.round(bmr * activityFactor);
    setMaintenanceCalories(calculatedMaintenanceCalories);
  };
  
  const calculateBMR = () => {
    const weightInKg = parseFloat(weight);
    const heightInCm = parseFloat(height);
    const ageInYears = parseFloat(age);
    
    let bmr = 0;

    if (gender === 'male') {
      bmr = 88.362 + (13.397 * weightInKg) + (4.799 * heightInCm) - (5.677 * ageInYears);
    } else if (gender === 'female') {
      bmr = 447.593 + (9.247 * weightInKg) + (3.098 * heightInCm) - (4.330 * ageInYears);
    }

    return bmr;
  };
  const navigate= useNavigate();
  const tohome=()=>{
    navigate("/")
}
const toTrack=()=>{
  navigate("/track")
}
  const getActivityFactor = (level) => {
    const activityFactors = {
      sedentary: 1.2,
      lightlyActive: 1.375,
      moderatelyActive: 1.55,
      veryActive: 1.725,
      superActive: 1.9,
    };

    return activityFactors[level] || 1.2; // Default to sedentary
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  };

  const inputStyle = {
    marginBottom: '10px',
    width: '100%',
  };

  return (
    <>
    <Button onClick={tohome}  variant="contained" color="success">
    Return to main
  </Button>
    
    <div style={{ textAlign: 'center' }}>
      <h2 style={{marginBottom:"15px",marginTop:"10px"}}>BMR and Maintenance Calorie Calculator</h2>
      <div style={formStyle}>
        <TextField
          label="Weight (kg)"
          value={weight}
          onChange={(e) => setWeight(e.target.value)}
          style={inputStyle}
        />
        <TextField
          label="Height (cm)"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
          style={inputStyle}
        />
        <TextField
          label="Age (years)"
          value={age}
          onChange={(e) => setAge(e.target.value)}
          style={inputStyle}
        />
        <FormControl style={inputStyle}>
          <InputLabel>Gender</InputLabel>
          <Select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            style={{ width: '100%' }}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={inputStyle}>
          <InputLabel>Activity Level</InputLabel>
          <Select
            value={activityLevel}
            onChange={(e) => setActivityLevel(e.target.value)}
            style={{ width: '100%' }}
          >
            <MenuItem value="sedentary">Sedentary (little or no exercise)</MenuItem>
            <MenuItem value="lightlyActive">Lightly Active (light exercise/sports 1-3 days/week)</MenuItem>
            <MenuItem value="moderatelyActive">Moderately Active (moderate exercise/sports 3-5 days/week)</MenuItem>
            <MenuItem value="veryActive">Very Active (hard exercise/sports 6-7 days a week)</MenuItem>
            <MenuItem value="superActive">Super Active (very hard exercise/sports, physical job, or training)</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={calculateMaintenanceCalories}
          style={{ backgroundColor: '#3498db', color: '#ffffff', marginTop: '10px' }}
        >
          Calculate Maintenance Calories
        </Button>
        {maintenanceCalories !== null && (
          <div style={{ marginTop: '20px' }}>
            <p>Maintenance Calories: {maintenanceCalories}</p>
            <p>Your BMR: {calculateBMR().toFixed(2)}</p>
          </div>
        )}
      </div>
    </div>
    <div style={{display:"grid", marginTop:"15px", justifyContent:"center"}}>
    <h3>Done With calculating Your Calories according to your type,Wight,Height?</h3><br/>
    <h2 style={{textAlign:"center" , marginTop:"-15px"}}>Now <span style={{color:"red" , textDecorationThickness:"front"}}>Find</span> and <span style={{color:"red" , textDecorationThickness:"front"}}>Track</span> Calories in your food</h2>
    <Button onClick ={toTrack} style={{width:"30%" ,marginLeft:"35%",marginTop:"10px"}} variant="outlined" >Track Calories Here</Button>
    </div>
    
</>
    
  );
};

export default LoginForm;
