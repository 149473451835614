import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select,MenuItem,Container,FormControl,InputLabel, Typography, Paper, Grid ,Card, CardContent,  CardActions} from '@mui/material';
import { AppBar, Toolbar, Button ,Fab,CardActionArea,CardMedia} from '@mui/material';
import Protein from "./Data/Protein";
import Carbs from "./Data/Carbs";
import Fibre from "./Data/Fibre";

const Home = (value) => {
  const [record, setRecord] = useState([]);
  const [search, setSearch] = useState([]);
  const[nutrientFood,setProteinvalue]= useState([]);
  const[activityLevel , setActivityLevel] = useState('');
   // HANDLING SELECT BUTTON

   
   const handlep=(selected)=>{
    console.log("btn");
    let foods=[];
    //const foodp=Protein.map(items=>item.)
    console.log("activity :"+selected);
   if(selected==="protein"){
    foods=Protein;
   }
   else if(selected ==="carbs"){
    foods=Carbs;
   }
  else if(selected ==="fibre"){
    foods=Fibre
  }
  else{
    foods=[];
  }
   setProteinvalue(foods);  
    };
  
  //const[proteincontent,setProteincontent] = useState([]);
  
const navigate = useNavigate();
  const apikey = "24KA3uZVycomZ+YPYFTjKA==wRrBMtWcpfR83g7k";
  let url;

 
    const listItemStyle = {
      marginBottom: '10px',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
    };
    const inputStyle = {
      marginBottom: '10px',
      width: '100%',
    };
    const tomain=()=>{
      navigate("/");
    }
  console.log(activityLevel);
//console.log(nutrientFood);
  const searchchange = (e) => {
    setSearch(e.target.value);
  };
  const searchbtn = () => {
    url = "https://api.api-ninjas.com/v1/exercises?muscle=" + search;
    console.log(url);
    getExercise();
  };
  const h3Style = {
    margin: '0',
    fontSize: '20px',
    color: '#333',
  };
  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };
  
  const getExercise = async () => {
    console.log("inside");
    try {
      const response = await axios.get(url, {
        headers: {
          "X-Api-Key": apikey,
        },
      });
      console.log(response.data);
      //console.log("inside");
      setRecord(response.data);
      //console.log(response.data.exercise);
    } catch (err) {
      console.log("error :" + err);
    }
  };

  return (
    <>
    <Button onClick={tomain} variant="contained">Back To Main</Button>
  {/* NavBar */}
  <h3 style={{textAlign:"center"}}>You Can search exercise and get list of nutrients here </h3>
  <input
        style={{ paddingBottom: "5px", marginLeft: "70%", width: "200px",padding:"0.5rem",borderRadius:"2px" }}
        placeholder="search muscle"
        onChange={searchchange}
        name="field"
      />
      <button onClick={searchbtn}>Search</button>
  {/* MAIN HOME PAGE */}
  <div style={{ width: '100vw', height: '100vh', display: 'flex' }}>
    <Grid container>
      {/* Left Container */}
      <Grid item xs={6}>
        <Paper style={{ height: '100%', backgroundColor: '#f0f0f0' }}>
          {/* Content for the left container */}
          <div style={{ marginLeft: "10px", display: "flex", justifyContent: "start" }}>
            <div style={{ maxWidth: "1000px", width: "150%", border: "1px solid #ccc", borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              <h2 style={{ textAlign: "center", marginBottom: "5px" }}>Select Nutrient to Get List of Foods that Are Rich</h2>
              {/* ... Rest of your code ... */}
              <FormControl style ={inputStyle}  >
          {/* <InputLabel style={{color:"red"}}>Select Nutrient</InputLabel> */}
          <Select
            value={activityLevel}
            onChange={(e) => {
              console.log("main activity :"+ e.target.value);
            setActivityLevel(e.target.value);
              handlep(e.target.value);
            }
          }
            style={{ width: '100%' }}
          >
            <MenuItem value="protein">Protein (Used for muscle recovering)</MenuItem>
            <MenuItem value="carbs">Carbohydrates (Used for Energy)</MenuItem>
            <MenuItem value="fibre">Fibre (Used for Gut health)</MenuItem>
           
          </Select>
        </FormControl>
      {nutrientFood.length >0 && nutrientFood.map((food)=>{
        return (
          <>
        <div style={listItemStyle}>
          <h3 style={h3Style}>{food.englishName}</h3>
          ({food.hindiName})
          <p>{food.Content}</p>
          </div>
          </>
        )
      })
      }
      <button style={buttonStyle} onClick={()=>{setProteinvalue(0)}}>close</button>
            </div>
          </div>
        </Paper>
      </Grid>

      {/* Right Container */}
      <Grid item xs={6}>
        <h3 style={{ textAlign: "center", backgroundColor: "#d0d0d0" }}>See Your Search Result Here</h3>
        <Paper style={{ height: '100%', backgroundColor: '#d0d0d0' }}>
          {/* Content for the right container */}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ maxWidth: "700px", width: "100%", border: "1px solid #ccc", borderRadius: "10px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)" }}>
              {record && record.map((item) => (
                <div key={item.id} style={{ marginBottom: "20px", padding: "10px", backgroundColor: "#f7f7f7", borderRadius: "6px" }}>
                  <h2 style={{ textAlign: "center", backgroundColor: "#ffc107", padding: "5px", borderRadius: "6px" }}>
                    Exercise
                  </h2>
                  <h1 style={{ textAlign: "center", fontSize: "24px", marginBottom: "10px" }}>{item.name}</h1>
                  <h3 style={{ textAlign: "center", backgroundColor: "#f0f0f0", padding: "5px", borderRadius: "6px", marginBottom: "-10px" }}>
                    How To Do:
                  </h3>
                  <p style={{ textAlign: "justify", fontSize: "16px", lineHeight: "1.6" }}>{item.instructions}</p>
                  </div>
              ))}
            </div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  </div>
</>
  );
};

export default Home;
