import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navi = useNavigate();
    const returnmain=()=>{
        navi("/landing")

    }
  return (
    <>
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          About Our Fitness App
        </Typography>
        <Typography variant="body1">
          Welcome to our fitness app, your all-in-one destination for tracking your health and achieving your fitness goals.
        </Typography>
        <Typography variant="body1">
          Our app provides a range of features designed to help you on your fitness journey:
        </Typography>
        <ul>
          <li>Calculate your daily calorie intake and track your food consumption</li>
          <li>Calculate your Body Mass Index (BMI) to assess your overall health</li>
          <li>Access a database of exercises to help you stay active and fit</li>
          <li>Search for exercises based on your preferences and fitness level</li>
          {/* Add more features if applicable */}
        </ul>
        <Typography variant="body1">
          Whether you're a beginner or an experienced fitness enthusiast, our app is here to support you every step of the way. 
        </Typography>
        <Typography variant="body1">
          We're committed to providing you with accurate information and tools to help you make informed decisions about your health and fitness.
        </Typography>
        <Typography variant="body1">
          Thank you for choosing our fitness app. Start your fitness journey today and make a positive change in your life!
        </Typography>
      </Paper>
    </Container>

    <div >
        <button onClick={returnmain} style={{marginLeft:"45%"}}>
            Back to Main
        </button>
    </div>
    </>
  );
};

export default About;