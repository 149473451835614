const Protein = [
  {
    englishName: "Lentils",
    hindiName: "Masoor Dal",
    Content: "17.9g per 100g",
  },
  {
    englishName: "Chickpeas",
    hindiName: "Chana",
    Content: "8.9g per 100g",
  },
  {
    englishName: "Black Beans",
    hindiName: "Black Beans",
    Content: "8.9g per 100g",
  },
  {
    englishName: "Quinoa",
    hindiName: "Quinoa",
    Content: "4.1g per 100g",
  },
  {
    englishName: "Tofu",
    hindiName: "Tofu",
    Content: "8.1g per 100g",
  },
  {
    englishName: "Greek Yogurt",
    hindiName: "Greek Yogurt",
    Content: "10g per 100g",
  },
  {
    englishName: "Cottage Cheese",
    hindiName: "Paneer",
    Content: "11g per 100g",
  },
  {
    englishName: "Almonds",
    hindiName: "Badam",
    Content: "21.2g per 100g",
  },
  {
    englishName: "Peanuts",
    hindiName: "Mungfali",
    Content: "25.8g per 100g",
  },
  {
    englishName: "Chia Seeds",
    hindiName: "Chia Beej",
    Content: "16.5g per 100g",
  },
  {
    englishName: "Eggs",
    hindiName: "Ande",
    Content: "13g per 100g",
  },
];
export default Protein;
