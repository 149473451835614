import Header from './Header/Header'
import './Hero.css'
import "./App.css"
import HeroMain from "../Client/images/hero_image.png"
import Heart from "../Client/images/heart.png"
import HeroBack from "../Client/images/hero_image_back.png"
import { Button,TextField } from '@mui/material'
import { useState } from 'react'
import Program from './Cards/Program'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

const Landingpage = () => {

//     const num=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
//     const r1 =  Math.floor(Math.random()*num.length);
//     const r2 =  Math.floor(Math.random()*num.length);
//     const r3 =  Math.floor(Math.random()*num.length);
//     const r4 =  Math.floor(Math.random()*num.length);
//     const quotes = details.find(item=>item.id===r1);
//     const quotes2 = details.find(item=>item.id===r2);
//     const quotes3 = details.find(item=>item.id===r3);
//     const quotes4 = details.find(item=>item.id===r4);
//     const scrollToBottom=()=>{
//         window.scrollTo({
//             top:document.body.scrollHeight,
//             behavior:"smooth",
//         }) 
//     }
//        //to guide user to differnt pages
//     const navigate = useNavigate();
//     const tohome=()=>{
//         window.alert("please login first");
//         //navigate("/home");
//     }
//     const toregister=()=>{
//         navigate('/register')
//     }
//     const login=()=>{
//         navigate("/login");
//     }
//     const toabout=()=>{
//         navigate("/about");
//     }
//     return(
        
//         <div style={{backgroundColor:"beige"}}>
//            {/* BUTTON FOR SCROLL TO LAST  */}
//         <Fab
//       color="primary"
//       aria-label="Scroll to bottom"
//       style={{ position: 'fixed', bottom: '20px', right: '20px' }}
//       onClick={scrollToBottom}
//     >
//       see more
//     </Fab>

//     {/* NAVIGATION BAR */}
//         <AppBar position="static">
//       <Toolbar>
//         <Typography variant="h6" sx={{ flexGrow: 1 }}>
//           MyfitnessYouth[MFY]
//         </Typography>
//         <Button onClick={tohome} color="inherit">Home</Button>
//         <Button onClick={toabout} color="inherit">About</Button>
//         <Button onClick={login} color="inherit">Login</Button>
//         <Button onClick={toregister}color="inherit">Signup</Button>
//       </Toolbar>
//     </AppBar>

//     {/* IMAGES AND QUOTE */}
//     <div style={{ backgroundColor: '#f0f0f0', paddingTop: '50px', paddingBottom: '50px' }}>
//   <Container maxWidth="lg" style={{ marginBottom: '30px' }}>
//     <Paper elevation={3} style={{ padding: '20px', display: 'flex', alignItems: 'center', backgroundColor: 'lavender', borderRadius: '10px' }}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={8}>
//           <img src="https://images.unsplash.com/photo-1581009146145-b5ef050c2e1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGd5bXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="" style={{ width: '100%', borderRadius: '10px' }} />
//         </Grid>
//         <Grid item xs={12} sm={4}>
//           <Typography variant="h5" gutterBottom style={{ textAlign: 'center' }}>
//             {quotes3.quote}
//           </Typography>
//         </Grid>
//       </Grid>
//     </Paper>
//   </Container>
// </div>
//     <Container maxWidth="lg" >
//       <Paper elevation={3} style={{ padding: '20px', display: 'flex',alignItems: 'center' ,backgroundColor:'lavender' }}>
//         <Grid container spacing={2} >
//           <Grid item xs={12} sm={8}>
//           <img src="https://images.unsplash.com/photo-1581009146145-b5ef050c2e1e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGd5bXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="" style={{maxWidth:"100%"}} />
//           </Grid>
//           <Grid item xs={12} sm={4}>
            
//             <Typography variant="h5" gutterBottom>
//               {
//                 quotes3.quote
//               }
//             </Typography>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Container>
//     <Container maxWidth="lg">
//       <Paper elevation={3} style={{ padding: '20px', display: 'flex', backgroundColor:'lavender',alignItems: 'center' }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={8}>
//           <img src="https://images.unsplash.com/photo-1534438327276-14e5300c3a48?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Z3ltfGVufDB8fDB8fHww&auto=format&fit=crop&w=900&q=60" alt="" style={{ maxWidth: '80%' }} />
//           </Grid>
//           <Grid item xs={12} sm={4}>
    
//             <Typography variant="h5" gutterBottom>
                
//              {
//                 quotes2.quote
//               }
//             </Typography>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Container>
//     <Container maxWidth="lg">
//       <Paper elevation={3} style={{ padding: '20px', display: 'flex', backgroundColor:'lavender',alignItems: 'center' }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={8}>
//           <img src="https://images.unsplash.com/photo-1571902943202-507ec2618e8f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=775&q=80" alt="" style={{maxWidth:"80%" , width:"70%"}} />
//           </Grid>
//           <Grid item xs={12} sm={4}>
            
//             <Typography variant="h5" gutterBottom>
//               {
//                 quotes.quote
//               }
//             </Typography>
//           </Grid>
//         </Grid>
//       </Paper>
      
//     </Container>

//     <Container maxWidth="lg">
//       <Paper elevation={3} style={{ padding: '20px', display: 'flex', backgroundColor:'lavender',alignItems: 'center' }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={8}>
//           <img src="https://images.unsplash.com/photo-1606889464198-fcb18894cf50?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NzJ8fGd5bXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="" style={{maxWidth:"100%" , height:"80vh"}} />
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Typography variant="h5" gutterBottom>
//               {
//                 quotes4.quote
//               }
//             </Typography>
//           </Grid>
//         </Grid>
//       </Paper>
//     </Container>


//     {/* CARD DETAILS */}


//     <h1 style={{textAlign:"center"}}>What you can do here</h1>
//     <Container maxWidth="md">
//       <Grid container spacing={3}>
//         <Grid item xs={12} md={4}>
//         <Card sx={{ maxWidth: 345 }}>
//       <CardActionArea>
//         <CardMedia
//           component="img"
//           height="140"
//           image="https://images.unsplash.com/photo-1586769852836-bc069f19e1b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
//           alt="green iguana"
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             Search Exercise
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Can search exercise by muscles
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//         </Grid>
//         <Grid item xs={12} md={4}>
//         <Card sx={{ maxWidth: 345 }}>
//       <CardActionArea>
//         <CardMedia
//           component="img"
//           height="140"
//           image="https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fGRpZXQlMjBwbGFufGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
//           alt="green iguana"
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             Diet Plan
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             Get many diet plan acoording to your need
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//         </Grid>
//         <Grid item xs={12} md={4}>
//         <Card sx={{ maxWidth: 345 }}>
//       <CardActionArea>
//         <CardMedia
//           component="img"
//           height="140"
//           image="https://images.unsplash.com/photo-1618939291225-8d558ea4369f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Ym1pfGVufDB8fDB8fHww&auto=format&fit=crop&w=400&q=60"
//           alt="green iguana"
//         />
//         <CardContent>
//           <Typography gutterBottom variant="h5" component="div">
//             BMI AND CALORIES
//           </Typography>
//           <Typography variant="body2" color="text.secondary">
//             can find maintance calories and their weight
//           </Typography>
//         </CardContent>
//       </CardActionArea>
//     </Card>
//         </Grid>
//       </Grid>
//     </Container>
//     <h2 style={{textAlign:"center"}}>
//         ....And much more please Login to use all our features<br/>
//         <Button onClick={login} variant="contained" color="success">
//             Login
//         </Button>
//     </h2>
   
//     </div>
//)
  const navigate=useNavigate();
  const register=()=>{
    navigate("/register")
  }
  const trans={type:'spring',duration:3}
    return(
      <>
      <div  style={{backgroundColor:"#565960"}}>  
      <div className='hero'>
        
        <div className='left-h'>
          <Header/>
          <div className="the-best-ad">
           <motion.div  initial={{left:'238px'}}
              whileInView={{left:'8px'}}
              transition={{...trans , type:'tween'}}>
             
             </motion.div>
            <span>The Best Fitness Website You Witness</span>
          </div>
                {/* Hero text */}
          <div className="hero-text">
            <div>
              <span className='stroke-text'>Shape </span>
              <span>Your</span>
            </div>
            <div>
            <span>Ideal body</span>
            </div>
            <div>
            <span>
              In here we will help you track calories , See your Food , see your exercise and many more  
            </span>
            </div>
          </div>

          {/* Figures */}
          <div className='figures'>
            <div>
              <span>2600</span>
              <span>Total Calories</span>
            </div>
            <div>
              <span>1000+</span>
              <span>members</span>
            </div>
            <div>
              <span>10+</span>
              <span>Functions</span>
            </div>
          </div>

          {/* Buttons */}
          <div  className="hero-button">
          <button onClick={register} className='btn'>Get Started</button>
          <button className='btn'>Learn more</button>
          </div>
          </div>
        <div className='right-h'>
          <button className='btn'>Join now</button>

          <motion.div 
           initial={{right:"-1rem"}}
          transition={trans}
          whileInView={{right:'5rem'}}
         
          className='heart-rate'>
            <img src={Heart} alt=''/>
              <span>Heart Rate</span>
              <span>116 bpm</span>
          </motion.div>
          {/* images */}
          <img src={HeroMain} alt="" className='hero-image' />
          <motion.img 
          initial={{right:"11rem"}}
          whileInView={{right:"23rem"}}
          transition={trans}
          src={HeroBack} alt="" className='hero-image-back'/>
        </div>
      </div>
      <Program/>
      </div>
      </>
    )

   }

export default Landingpage