import { TextField,Button, Table, TableBody,TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const BMI = () => {
    const [modal,setModal] = useState(false);
    const[bmi,setBmi] = useState({
        height:'',
        weight:'',
    });
    let[bmivalue,setBmivalue] = useState(null);
    const navigate=useNavigate();
    const tohome=()=>{
        navigate("/")
    }
    const btn=()=>{
        setModal(true);
    }
    const handleinput=(e)=>{
        const{name,value} = e.target;
        setBmi((pv)=>({...pv,[name]:value}))
    }
     
    let bmiValue;
    const calculate=()=>{
        let w=bmi.weight;
        let h=bmi.height;
        bmivalue= (w/Math.pow(h,2))
        setBmivalue(bmivalue);
        console.log(w +" "+h)
        console.log(bmivalue);
    }
    
    const MyModal=(e)=>{
        
    return(
        <>
        <div className='modal-wrapper'></div>
        <div className='modal-container'>
        <button  onClick={() => setModal(false)}>close</button>

        <h1 style={{marginBottom:"8px"}}>BMI Calculator</h1>
        
        <h4 style={{marginBottom:"8px"}}>(in m)</h4>
        <TextField type='number' value={bmi.height} name='height' onChange={handleinput} id="outlined-basic" label="Height" variant="outlined" />

        <h4 style={{marginBottom:"8px"}}>(in kg)</h4>
        <TextField value={bmi.weight} name='weight'onChange={handleinput} id="outlined-basic" label="Weight" variant="outlined" />
        <br/>
        
        <Button onClick={calculate} variant="contained">Calculate</Button>
            
        <br/>
        
        <h3>
           Your BMI is: {bmivalue};

        </h3>
        </div>
        </>
    )
    }
  return (
   <>
   <div>
    <Button onClick={tohome} variant="contained">Back To main</Button>
    </div>
   <h2 style={{textAlign :"center"}}>
    Table
   </h2>
    
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Classification</TableCell>
            <TableCell>BMI range - kg/m²</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Severe Thinness</TableCell>
            <TableCell>&lt; 16</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Moderate Thinness</TableCell>
            <TableCell>16 - 17</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mild Thinness</TableCell>
            <TableCell>17 - 18.5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Normal</TableCell>
            <TableCell>18.5 - 25</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Overweight</TableCell>
            <TableCell>25 - 30</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Obese Class I</TableCell>
            <TableCell>30 - 35</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Obese Class II</TableCell>
            <TableCell>35 - 40</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Obese Class III</TableCell>
            <TableCell>&gt; 40</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <div style={{marginLeft:"41%"}}>
    <h2>Click here To check Your BMI</h2>
    
    <button onClick={btn} style={
        {width:"15%" , 
        marginLeft:"10%",
        background: '#3498db', // Button background color
        color: '#ffffff',      // Button text color
        padding: '10px 20px',  // Button padding
        border: 'none',        // Remove button border
        borderRadius: '6px',   // Button border radius
        cursor: 'pointer',     // Show hand cursor on hover
        ransition: 'background-color 0.3s',
    }}
    onMouseEnter={(e)=>{
        e.target.style.backgroundColor = "#2980b9"
    }}
    onMouseLeave={(e) => {
        e.target.style.backgroundColor = '#3498db'; // Revert background color after hover
      }}
    >
        Check your BMI
    </button>
    {modal && <MyModal/>}
    <br/>
    
    
    </div>
   </>
  )
}

export default BMI