import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const TrackCalories = () => {
  const [datas, setData] = useState();
  const [query, setQuery] = useState('');
  const [url, setUrl] = useState('');
  const[track,setTracking] =useState([]);
 const[total,setTotal] = useState(0);
 //const[totalprotein,setProtein] = useState(0);
   const handleInput = (e) => {
    setQuery(e.target.value);
  };

  const handle = () => {
    const apiUrl = "https://api.calorieninjas.com/v1/nutrition?query=" + query;
    setUrl(apiUrl);
    
    
  };

  async function getData() {
    try {
      const apiKey = "2fEUES5GR9ZWzs756b2pQA==hPQIrJIXq9iJrKxF";
      const res = await axios.get(url, {
        headers: { 'X-Api-Key': apiKey },
      });
      setData(res.data.items[0]);
      if(res.data.items[0] &&res.data.items[0] !== "undefined"){
        console.log("inside tracking");
         trackcalories(res.data.items[0].calories);
         //trackprotein(res.data.items[0].protein_g);
     }
    } catch (err) {
      console.log("error: " + err);
    }
  }
  const trackcalories=(calorie)=>{
    console.log("inside 2 tracking");
     setTotal(total + parseFloat(calorie))
  }
//   const trackprotein=(protein)=>{
//     console.log("inside 2 tracking");
//      setProtein(total + parseFloat(protein))
//   }
  useEffect(() => {
    if (url) {
      getData();
    }
  }, [url]);
  console.log(total);

  return (
    <>
    
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <TextField
          onChange={handleInput}
          label="Enter Food With Quantity"
          variant="filled"
          color="success"
          focused
          style={{ marginRight: '10px' }}
        />
        <Button onClick={handle} variant="outlined">
          Fetch Nutrition
        </Button>
      </Paper>
      <div style={{ marginTop: '20px' }}>
        {datas ? (
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6">Nutritional Information:</Typography>
            <TableContainer>
              <Table>
                {/* ... Table structure ... */}
                <TableHead>
                  <TableRow>
                    <TableCell>Nutrient</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Calories</TableCell>
                    <TableCell>{datas && datas.calories !== undefined ? datas.calories : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Protein</TableCell>
                    <TableCell>{datas.protein_g !== undefined ? datas.protein_g : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Carbs</TableCell>
                    <TableCell>{datas.carbohydrates_total_g !== undefined ? datas.carbohydrates_total_g : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Fiber</TableCell>
                    <TableCell>{datas.fiber_g !== undefined ? datas.fiber_g : 'N/A'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Typography variant="subtitle1">Enter a food item and click "Fetch Nutrition"</Typography>
        )}
        <h1>See your total calories here</h1>
        <h3>Total Calories : {total}</h3>
        {/* <h3>Total Protein :{totalprotein}</h3> */}
        
      </div>
    </div>
    </>
  );
};

export default TrackCalories;
