const Carbs = [
  {
    englishName: "Brown Rice",
    hindiName: "Brown Rice",
    Content: "23.5g per 100g",
  },
  {
    englishName: "Oats",
    hindiName: "Oats",
    Content: "66.3g per 100g",
  },
  {
    englishName: "Whole Wheat Bread",
    hindiName: "Ghehu ki bread",
    Content: "49.1g per 100g",
  },
  {
    englishName: "Sweet Potatoes",
    hindiName: "ShakarKandi",
    Content: "20.1g per 100g",
  },
  {
    englishName: "Quinoa",
    hindiName: "Anaj",
    Content: "21.3g per 100g",
  },
  {
    englishName: "Bananas",
    hindiName: "Kela",
    Content: "22g per 100g",
  },
  {
    englishName: "Chickpeas",
    hindiName: "Chana",
    Content: "27.4g per 100g",
  },
  {
    englishName: "Beans",
    hindiName: "Rajma",
    Content: "22.3g per 100g",
  },
  {
    englishName: "Corn",
    hindiName: "Makka",
    Content: "74.3g per 100g",
  },
  {
    englishName: "Whole Wheat Pasta",
    hindiName: "Ghehu ke pasta",
    Content: "37.7g per 100g",
  },
];
export default Carbs;
